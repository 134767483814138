import React from 'react'
import * as Styled from '../../styled'
import { FieldContainerProps } from './interface'

const FieldContainer = ({
  label,
  required,
  children,
}: FieldContainerProps): JSX.Element => (
  <Styled.FieldWrapper>
    <Styled.LabelWrapper>
      <Styled.Label>
        {label}
        {required && <Styled.Required>*</Styled.Required>}
      </Styled.Label>
    </Styled.LabelWrapper>
    {children}
  </Styled.FieldWrapper>
)

export default FieldContainer
