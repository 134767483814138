import React, { useRef } from 'react'
import FieldError from '../../partials/OmbudsmanPage/ContactHelpOmbudsman/FormContact/components/FieldError'
import { InputFileProps } from './interface'
import * as Styled from './styled'

const InputFile = ({ files, onChange }: InputFileProps): JSX.Element => {
  const inputFileRef = useRef<any>()

  return (
    <>
      <Styled.ButtonFile
        type="button"
        onClick={() => inputFileRef?.current?.click()}
      >
        {files?.length ? (
          files?.map(
            (file: File, index: number) =>
              `${file.name}${index < files.length - 1 ? ', ' : ''}`
          )
        ) : (
          <Styled.Placeholder>
            Arraste ou selecione um ou mais arquivos
          </Styled.Placeholder>
        )}
      </Styled.ButtonFile>
      <input
        type="file"
        accept=".jpg,.jpeg,.png,.mp3,.mp4,.pdf,.mov,.opus,.ogg"
        name="anexos"
        multiple
        id="anexos"
        ref={inputFileRef}
        hidden={true}
        onChange={onChange}
      />
      {files?.map(
        File =>
          File.name.length >= 215 && (
            <FieldError>
              Os nomes dos arquivos devem conter no máximo 215 caracteres.
            </FieldError>
          )
      )}
    </>
  )
}

export default InputFile
