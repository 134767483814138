import axios from '../../../../../../utils/axios'
import getData from './getData'
import { dispatchEventConversionsAPI } from '../../../../../../utils/dispatchEventConversionsAPI'
import { SetStateAction } from 'react'
import { AxiosError } from 'axios'

interface SubmitFilesProps {
  dataUpload?: UploadValues[]
  data: FormValues
  phone: string
  setStatus: (value: SetStateAction<Loading>) => void
}

interface SubmitFormProps extends SubmitFilesProps {
  attachments?: UploadObject[]
}

export const submitFiles = (props: SubmitFilesProps) => {
  const { dataUpload, data, phone, setStatus } = props

  axios
    .post('https://g.cora.com.br/avaliacao/ouvidoria/upload', dataUpload)
    .then((response: UploadResponse) => {
      submitForm({ data, phone, setStatus, attachments: response?.data })
    })
    .catch((error: AxiosError) => {
      (error.response?.status === 413 || error.response?.status === undefined) ? setStatus('limitSizeUpload') : setStatus('error')
    })
}

export const submitForm = (props: SubmitFormProps): void => {
  const { data, phone, setStatus, attachments } = props
  axios
    .post(
      'https://api.hsforms.com/submissions/v3/integration/submit/6975423/9e8c0d6c-e30b-4e38-bdd6-eb65667693c9',
      getData(data, attachments, phone)
    )
    .then(() => {
      dispatchEventConversionsAPI(data.email)
      setStatus('success')
    })
    .catch(() => {
      setStatus('error')
    })
}
