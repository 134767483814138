import { DataModal } from "./interface";

export const dataModal: DataModal = {
  success: {
    type: 'success',
    title: 'Obrigado pelo envio!',
    subtitle:
      'O seu chamado na Ouvidoria Cora foi aberto e o prazo de resposta é de até 10 dias úteis, mas não se preocupe! Tentaremos te responder o mais rápido possível.',
  },
  error: {
    type: 'error',
    title: 'Ops! Ocorreu um erro.',
    subtitle: 'Por favor, preencha o formulário novamente.',
  },
  limitSizeUpload: {
    type: 'error',
    title: 'Ops! Ocorreu um erro.',
    subtitle: 'Você excedeu o tamanho máximo permitido para anexos (32mb). Por favor, verifique o tamanho dos seus arquivos e tente enviar novamente.',
  },
  loading: {
    type: 'loading',
    title: 'Enviando suas respostas.',
    subtitle: 'Por favor, aguarde enquanto salvamos suas informações.',
  },
}
