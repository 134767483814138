import styled, { css } from 'styled-components';

interface StyledHeadingProps {
  dark: boolean
  center: boolean
  size: 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall'
}

export const Heading = styled.h1<StyledHeadingProps>`
  color: ${({ dark }) => (dark ? '#FFF' : '#202020')};

  @media screen and (min-width: 768px) {
    text-align: ${({ center }) => (center ? 'center' : 'initial')};
  }

  ${({ size }) =>
    size === 'xlarge' &&
    css`
      font-size: 32px;
      line-height: 40px;

      @media screen and (min-width: 375px) {
        font-size: 40px;
        line-height: 48px;
      }

      @media screen and (min-width: 768px) {
        font-size: 56px;
        line-height: 64px;
      }

      @media screen and (min-width: 1280px) {
        font-size: 72px;
        line-height: 80px;
      }

      @media screen and (min-width: 1920px) {
        font-size: 88px;
        line-height: 96px;
      }
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      font-size: 28px;
      line-height: 38px;
      margin-top: -100px;
      padding-top: 100px;

      @media screen and (min-width: 768px) {
        font-size: 32px;
        line-height: 48px;
      }

      @media screen and (min-width: 1280px) {
        font-size: 40px;
        line-height: 56px;
      }

      @media screen and (min-width: 1920px) {
        font-size: 48px;
        line-height: 64px;
      }
    `
  }

  ${({ size }) =>
    size === 'medium' &&
    css`
      font-size: 24px;
      line-height: 32px;

      @media screen and (min-width: 768px) {
        font-size: 28px;
        line-height: 36px;
      }

      @media screen and (min-width: 1280px) {
        font-size: 28px;
        line-height: 38px;
      }
    `
  }

  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: 16px;
      line-height: 24px;

      @media screen and (min-width: 768px) {
        font-size: 20px;
        line-height: 30px;
      }

      @media screen and (min-width: 1280px) {
        font-size: 22px;
        line-height: 32px;
      }
    `
  }

  ${({ size }) =>
    size === 'xsmall' &&
    css`
      font-size: 12px;
      line-height: 20px;

      @media screen and (min-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
    `
  }
`
