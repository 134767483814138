import React from 'react'
import * as Styled from './styled'

import Link from '../../../components/Link'

import Heading from '../../../components/Heading'
import { dataReport } from './data'

const ReportOmbudsman = (): JSX.Element => (
  <Styled.Section>
    <div className="container container--no-mobile-margin">
      <Styled.Wrapper>
        <Heading headingLevel="h2" size="xlarge">
          Acesse nossos relatórios de Ouvidoria
        </Heading>
        <Styled.Description>
          Conheça o trabalho da nossa Ouvidoria para melhorar a sua experiência
          em toda a jornada na Cora.
        </Styled.Description>

        <Styled.LinksWrapper>
          {dataReport.map((item, index) => (
            <Link
              key={`report-${index}`}
              href={item.href}
              target="_blank"
              downloadName={item.downloadName}
            >
              {item.title}
            </Link>
          ))}
        </Styled.LinksWrapper>
      </Styled.Wrapper>
    </div>
  </Styled.Section>
)

export default ReportOmbudsman
