import React from 'react'
import * as Styled from './styled'
import Success from '../../images/modal/Success.svg'
import Error from '../../images/modal/Error.svg'
import Loading from '../../images/modal/Loading.svg'
import Close from '../../images/modal/Close.svg'

const Modal = ({
  type = 'success',
  title,
  subtitle,
  active = false,
  onClose,
}: IModalProps): JSX.Element => {
  const objType = {
    success: <img src={Success} alt="Sucesso" />,
    error: <img src={Error} alt="Erro" />,
    loading: (
      <Styled.Rotate>
        <img src={Loading} alt="Carregando" />
      </Styled.Rotate>
    ),
  }
  return (
    <Styled.Wrapper active={active}>
      <Styled.Box>
        {onClose && (
          <Styled.WrapperClose>
            <Styled.ButtonClose onClick={onClose}>
              <img src={Close} alt="Fechar Modal" />
            </Styled.ButtonClose>
          </Styled.WrapperClose>
        )}
        <Styled.WrapperBox>
          {objType[type]}
          <Styled.Title>{title}</Styled.Title>
          {subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
        </Styled.WrapperBox>
      </Styled.Box>
    </Styled.Wrapper>
  )
}

export default Modal
