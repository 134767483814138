import React from 'react'
import { hasErrors } from '../../../../../../../utils/formHelpers'
import * as Styled from '../../styled'
import FieldContainer from '../FieldContainer'
import FieldError from '../FieldError'
import { FieldContactProps } from './interface'

const FieldContact = ({
  type,
  label,
  required,
  name,
  placeholder,
  errors,
  onClick,
  refs,
}: FieldContactProps): JSX.Element => {
  const validateType = {
    email: 'Email inválido',
    cpf_cnpj_current_owner: 'Documento inválido',
  }

  return (
    <FieldContainer required={required} label={label}>
      <Styled.Input
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        required={required}
        onClick={onClick}
        ref={refs}
      />
      {hasErrors(errors[name], 'message') && (
        <FieldError>{hasErrors(errors[name], 'message')}</FieldError>
      )}
      {hasErrors(errors[name], 'type') === 'validate' && (
        <FieldError>
          {(name === 'email' || name === 'cpf_cnpj_current_owner') &&
            validateType[name]}
        </FieldError>
      )}
    </FieldContainer>
  )
}

export default FieldContact
