import styled from 'styled-components'
import { device } from '../../styles/breakpoints'

export const DividerWrapper = styled.hr`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #dee4e9;
  border-left: none;
  width: 90%;
  @media ${device.laptop} {
    width: 50%;
  }
`
