import React from 'react'
import { Chat, Email, Sac, Whatsapp } from './icons'
import { Contact } from './interface'
import * as Styled from './styled'

export const contacts: Contact[] = [
  {
    icon: Chat,
    title: 'Nosso chat',
    description: (
      <>
        Acesse pelo seu aplicativo Cora. Possuímos atendimento humanizado e um
        assistente virtual, o Boto.
      </>
    ),
  },
  {
    icon: Email,
    title: 'Email',
    description: (
      <>
        Envie um email para{' '}
        <Styled.ContactEmphasis>meajuda@cora.com.br</Styled.ContactEmphasis>
      </>
    ),
  },
  {
    icon: Whatsapp,
    title: 'WhatsApp',
    description: (
      <>
        Você também pode falar com a gente via WhatsApp:
        <Styled.ContactEmphasis>(11) 93305-8198</Styled.ContactEmphasis>
      </>
    ),
  },
  {
    icon: Sac,
    title: 'SAC',
    description: (
      <>
        Capitais e regiões metropolitanas:
        <Styled.ContactEmphasis>(11) 5039-5715</Styled.ContactEmphasis>
        Demais localidades:
        <Styled.ContactEmphasis>0800 591 2350</Styled.ContactEmphasis>
      </>
    ),
  },
]
