import React, { useState } from 'react'
import Divider from '../components/Divider'
import Layout from '../components/Layout'
import SEO from '../components/seo'

import SideSection from '../partials/commons/SideSection'
import BannerOmbudsman from '../partials/OmbudsmanPage/BannerOmbudsman'
import ContactHelpOmbudsman from '../partials/OmbudsmanPage/ContactHelpOmbudsman'
import ContactOmbudsman from '../partials/OmbudsmanPage/ContactOmbudsman'
import ContactPhoneOmbudsman from '../partials/OmbudsmanPage/ContactPhoneOmbudsman'
import ReportOmbudsman from '../partials/OmbudsmanPage/ReportOmbudsman'

const OmbudsmanPage = (): JSX.Element => {
  const [toggleForm, setToggleForm] = useState(false)

  const toggle = (): void => {
    setToggleForm(true)
  }

  return (
    <Layout pageType="home" toggle={toggle}>
      <SEO
        title="Cora - Conta Digital feita para empreendedores e donos de negócio"
        description="A Cora é uma conta digital desenvolvida para empreendedores como você. Simples, sem burocracia e sem taxas. Se você empreende, você é Cora!"
        linkCanonical="ouvidoria"
      />

      <BannerOmbudsman />
      <ContactOmbudsman />
      <ContactHelpOmbudsman />
      <ReportOmbudsman />
      <Divider />
      <ContactPhoneOmbudsman />

      <SideSection
        toggleForm={toggleForm}
        setToggleForm={setToggleForm}
        idFinishForm="FormFinishedHome"
        pageType="home"
      />
    </Layout>
  )
}

export default OmbudsmanPage
