import styled from 'styled-components'
import { device } from '../../../../styles/breakpoints'

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  grid-gap: 3.2rem;
  margin-top: 3.2rem;

  @media ${device.tabletL} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.laptopM} {
    margin-top: 6.4rem;
  }
`

export const FieldWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;

  @media ${device.tabletL} {
    width: 90%;
  }
`

export const LabelWrapper = styled.div`
  padding-bottom: 1.6rem;
  display: flex;
  flex-direction: column;

  @media ${device.tabletL} {
    padding-left: 1.6rem;
  }
`

export const Label = styled.label`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #3b3b3b;
`

export const Input = styled.input`
  background-color: #ffffff;
  border-radius: 1.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1.6rem;
  width: 100%;
  height: 48px;
  border: none;

  @media ${device.laptopM} {
    height: 64px;
  }
`

export const ErrorWrapper = styled.div`
  margin-top: 1.8rem;
  position: absolute;
  z-index: 1;
`

export const Error = styled.span`
  color: #fe3e6d;
  font-size: 1.4rem;
  line-height: 2rem;
`

export const Required = styled.span`
  color: #fe3e6d;
`
