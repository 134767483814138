import styled from 'styled-components'
import { device } from '../../../../styles/breakpoints'

export const ContactItem = styled.div`
  display: grid;
  width: 100%;
  align-items: flex-start;

  @media ${device.tablet} {
    grid-template-columns: 120px 1fr;
    grid-gap: 3rem;
  }

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-row-gap: 5rem;
  }
`

export const WrapperIcon = styled.div`
  min-height: 80px;
  display: flex;
  align-items: flex-start;
`

export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  grid-row-gap: 2rem;
`

export const ContactTitle = styled.h3`
  font-family: 'Inter';
  font-weight: 700;
  color: #3b3b3b;
  font-size: 2.4rem;
`

export const ContactText = styled.p`
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  font-weight: 400;
  color: #3b3b3b;
  font-size: 1.4rem;
  line-height: 2.4rem;

  @media ${device.tabletL} {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  @media ${device.laptop} {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
`
