export const dataOptions = [
  { value: 'Elogio', description: 'Elogio' },
  { value: 'Reclamação', description: 'Reclamação' },
  { value: 'Sugestão', description: 'Sugestão' },
  { value: 'Crítica', description: 'Crítica' },
  { value: 'Denúncia', description: 'Denúncia' },
]

export const dataSubject = [
  { value: 'Acessos, login e afins', description: 'Acessos, login e afins' },
  { value: 'Atendimento', description: 'Atendimento' },
  { value: 'Boleto Cora', description: 'Boleto Cora' },
  { value: 'Cadastro', description: 'Cadastro' },
  { value: 'Cartão de crédito', description: 'Cartão de crédito' },
  { value: 'Cartão de débito', description: 'Cartão de débito' },
  { value: 'Cancelamento', description: 'Cancelamento' },
  { value: 'Cobrança em DDA', description: 'Cobrança em DDA' },
  { value: 'Conta com limitações', description: 'Conta com limitações' },
  { value: 'Conta Digital PJ', description: 'Conta Digital PJ' },
  {
    value: 'Contestação de transação',
    description: 'Contestação de transação',
  },
  { value: 'Entrega de cartão', description: 'Entrega de cartão' },
  { value: 'Pagamento de contas', description: 'Pagamento de contas' },
  { value: 'PIX', description: 'PIX' },
  { value: 'Transferências (TED)', description: 'Transferências (TED)' },
  { value: 'Ofício', description: 'Ofício' },
  { value: 'Outros', description: 'Outras funcionalidades' },
]

export const dataReset = {
  firstname: '',
  reason: '',
  subject: '',
  protocolNumber: '',
  cpf_cnpj_current_owner: '',
  phone: '',
  email: '',
  describe: '',
}
