import React from 'react'
import { Contact } from '../interface';
import * as Styled from './styled'

const ContactItem = ({ data }: { data: Contact }): JSX.Element => {
  const { icon, title, description } = data
  return (
    <Styled.ContactItem>
      <Styled.WrapperIcon>{icon}</Styled.WrapperIcon>
      <Styled.WrapperText>
        <Styled.ContactTitle>{title}</Styled.ContactTitle>
        <Styled.ContactText>{description}</Styled.ContactText>
      </Styled.WrapperText>
    </Styled.ContactItem>
  )
}

export default ContactItem
