import React from 'react'
import { hasErrors } from '../../../utils/formHelpers'
import { SelectProps } from './interface'
import * as Styled from './styled'

const Select = ({
  errors,
  name,
  id,
  children,
  required,
  refs,
  onChange,
  clearErrors,
  defaultValue
}: SelectProps): JSX.Element => (
  <Styled.InputSelect
    defaultValue={defaultValue}
    name={name}
    id={id}
    onChange={(event) => onChange && onChange(event)}
    required={required}
    className={errors && hasErrors(errors[name])}
    onClick={() => clearErrors && clearErrors(name)}
    ref={refs}
  >
    {children}
  </Styled.InputSelect>
)

export default Select
