import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Styled from './styled'
import InputMask from 'react-input-mask'
import { getMaskDocument, getMaskPhone } from '../../../../../utils/getMask'
import { hasErrors } from '../../../../../utils/formHelpers'
import preparedUploadFile from './utils/preparedUploadFile'
import { saveFiles } from './utils/saveFiles'
import { submitForm } from './utils/formContactAPI'
import Button from '../../../../components/Button'
import Select from '../../../../components/Select'
import InputFile from '../../../../components/InputFile'
import ModalOmbudsman from './components/ModalOmbudsman'
import { dataOptions, dataReset, dataSubject } from './data'
import {
  validateCNPJ,
  validateCPF,
  validateEmail,
} from '../../../../components/Form/validate'
import FieldContainer from './components/FieldContainer'
import FieldContact from './components/FieldContact'
import FieldError from './components/FieldError'

const FormContact = (): JSX.Element => {
  const {
    handleSubmit,
    register,
    errors,
    clearErrors,
    setValue,
    reset,
    formState,
  } = useForm()
  const [files, setFiles] = useState<File[]>([])
  const [document, setDocument] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [status, setStatus] = useState<Loading>()

  const onSubmit: SubmitHandler<FormValues> = (data: FormValues): void => {
    files?.length > 0
      ? preparedUploadFile(data, files, phone, setStatus)
      : submitForm({ data, phone, setStatus })
  }



  useEffect(() => {
    if (formState.isSubmitSuccessful && status === 'success') {
      setFiles([])
      reset(dataReset)
    }
  }, [formState, reset, status === 'success'])

  return (
    <Styled.Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <ModalOmbudsman status={status} setStatus={setStatus} />
      <FieldContact
        type="text"
        name="firstname"
        errors={errors}
        placeholder="Nome completo"
        label="Digite o nome do responsável pela empresa ou do titular da relação de consumo."
        refs={register({
          required: 'Campo obrigatório',
        })}
        onClick={() => clearErrors('firstname')}
        required
      />
      <FieldContainer required label="Selecione o motivo do contato.">
        <Select
          required
          defaultValue=""
          name="reason"
          id="reason"
          onChange={(event) => setValue('reason', event.target.value)}
          errors={errors}
          clearErrors={clearErrors}
          refs={register({
            required: 'Campo obrigatório',
          })}
        >
          <option disabled value="">
            Selecione
          </option>
          {dataOptions.map((option, index) => (
            <option value={option.value} key={index}>
              {option.description}
            </option>
          ))}
        </Select>
        {hasErrors(errors.reason, 'message') && (
          <FieldError>{hasErrors(errors.reason, 'message')}</FieldError>
        )}
      </FieldContainer>
      <FieldContainer
        required
        label="Devido a lei do sigilo bancário só podemos prestar atendimento ao
            titular da conta."
      >
        <InputMask
          mask={getMaskDocument(document)}
          onChange={(event) => setDocument(event.target.value)}
        >
          <Styled.Input
            type="text"
            name="cpf_cnpj_current_owner"
            id="cpf_cnpj_current_owner"
            placeholder="CPF do titular ou CNPJ"
            required
            ref={register({
              required: 'Campo obrigatório',
              validate: (value: string) =>
                value.length === 15 ? validateCPF(value) : validateCNPJ(value),
            })}
            onClick={() => clearErrors('cpf_cnpj_current_owner')}
          />
        </InputMask>
        {hasErrors(errors.cpf_cnpj_current_owner, 'message') && (
          <FieldError>
            {hasErrors(errors.cpf_cnpj_current_owner, 'message')}
          </FieldError>
        )}
        {hasErrors(errors.cpf_cnpj_current_owner, 'type') === 'validate' && (
          <FieldError>Documento inválido</FieldError>
        )}
      </FieldContainer>
      <FieldContainer
        label="Escreva em poucas palavras o assunto da mensagem."
        required
      >
        <Select
          required
          defaultValue=""
          name="subject"
          id="subject"
          onChange={(event) => setValue('subject', event.target.value)}
          errors={errors}
          clearErrors={clearErrors}
          refs={register({
            required: 'Campo obrigatório',
          })}
        >
          <option disabled value="">
            Selecione o assunto do contato
          </option>
          {dataSubject.map((option, index) => (
            <option value={option.value} key={index}>
              {option.description}
            </option>
          ))}
        </Select>
        {hasErrors(errors.subject, 'message') && (
          <FieldError>Campo obrigatório</FieldError>
        )}
      </FieldContainer>
      <FieldContact
        type="email"
        name="email"
        errors={errors}
        placeholder="Email"
        label="Digite o e-mail de cadastro vinculado a sua conta Cora."
        refs={register({
          required: 'Campo obrigatório',
          validate: (value: string) => validateEmail(value),
        })}
        onClick={() => clearErrors('email')}
        required
      />
      <FieldContact
        type="text"
        name="describe"
        errors={errors}
        placeholder="O que aconteceu?"
        label="Conta pra gente o que aconteceu, incluindo data, horário, documentos e qual é o seu pedido para a nossa ouvidoria. Assim, conseguimos buscar a solução do seu chamado com mais agilidade."
        refs={register({
          required: 'Campo obrigatório',
        })}
        onClick={() => clearErrors('describe')}
        required
      />
      <FieldContainer label="Digite o telefone de contato vinculado a sua conta Cora.">
        <InputMask
          mask={getMaskPhone(phone)}
          onChange={(event) => {
            setPhone(event.target.value)
          }}
        >
          <Styled.Input
            type="text"
            name="phone"
            id="phone"
            placeholder="Telefone"
          />
        </InputMask>
      </FieldContainer>
        <FieldContainer label="O envio de anexos colabora com o processo de análise do seu chamado.">
          <InputFile
            files={files}
            onChange={(event) => saveFiles(event, setFiles)}
          />
        </FieldContainer>
      <FieldContact
        type="text"
        name="protocolNumber"
        errors={errors}
        placeholder="Protocolo de atendimento"
        label="O protocolo de atendimento é aquele que foi fornecido a você pelo
            canal primário (chat, WhatsApp e e-mail), na primeira tentativa de
            resolução do seu caso."
        refs={register({
          required: 'Campo obrigatório',
        })}
        onClick={() => clearErrors('protocolNumber')}
        required
      />
      <Styled.FieldWrapper>
        <FieldError
          errors={[
            '* Campos obrigatórios.',
            '** Ao clicar em enviar, você permite que a Cora entre em contato para a prestação desse serviço.',
          ]}
        ></FieldError>
        <Button
          disabled={status === 'loading'}
          type="submit"
          size="large"
          fluid
        >
          Enviar
        </Button>
      </Styled.FieldWrapper>
    </Styled.Form>
  )
}

export default FormContact
