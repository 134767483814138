import styled from 'styled-components'
import { device } from '../../../styles/breakpoints'

export const Section = styled.section`
  background-color: #f0f4f8;
  padding: 32px 24px;

  @media ${device.laptop} {
    padding: 60px 0;
  }
  @media ${device.laptopL} {
    padding: 80px 0;
  }
  @media ${device.desktop} {
    padding: 120px 0;
  }
`

export const Wrapper = styled.div`
  grid-column: 1/-1;
`

export const Subtitle = styled.h2`
  font-family: 'Inter';
  font-weight: 400;
  color: #202020;
  font-size: 2.4rem;
  line-height: 3.2rem;

  @media ${device.laptop} {
    font-size: 2.8rem;
    line-height: 4rem;
  }
  @media ${device.laptopL} {
    font-size: 3.2rem;
    line-height: 5rem;
  }
  @media ${device.desktop} {
    font-size: 4.8rem;
    line-height: 6.4rem;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  grid-row-gap: 32px;
  margin-top: 30px;
`

export const Text = styled.p`
  font-family: 'Inter';
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #3b3b3b;

  @media ${device.laptop} {
    font-size: 2.4rem;
    line-height: 40px;
  }
`

export const ContactHelpText = styled(Text)`
  margin-top: 48px;
  width: 85%;

  @media ${device.laptop} {
    margin-top: 96px;
  }
`

export const ContactHelpPhoneText = styled(Text)`
  font-weight: 700;
  color: #FE517B;
  margin: 0 8px;
  white-space: nowrap;
`
