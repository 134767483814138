import styled, { keyframes } from 'styled-components'
import { device } from '../../styles/breakpoints'

export const Wrapper = styled.div<{ active: boolean }>`
  position: fixed;
  z-index: 100;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(32, 32, 32, 0.7);
  opacity: ${({ active }) => (active ? '1' : '0')};
  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, max-height 0.4s ease;
`

export const Box = styled.div`
  border-radius: 24px;
  background-color: #ffffff;
  height: 80vh;
  width: 80vw;
`

export const WrapperBox = styled.div`
  height: calc(100% - 49px);
  padding: 20px;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  display: flex;
  flex-direction: column;
`

export const WrapperClose = styled.div`
  padding: 20px 20px 0 0;
  justify-content: end;
  align-items: flex-end;
  display: flex;
  width: 100%;
`

export const ButtonClose = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`

export const Title = styled.h1`
  font-size: 3.6rem;
  line-height: 4rem;
  font-weight: 400;
  text-align: center;
  color: #3b3b3b;

  @media ${device.laptop} {
    font-size: 5rem;
    line-height: 6rem;
  }

  @media ${device.desktop} {
    font-size: 8rem;
    line-height: 9.6rem;
  }
`

export const Subtitle = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
  text-align: center;
  color: #3b3b3b;

  @media ${device.tabletL} {
    font-size: 2.4rem;
    line-height: 3rem;
  }

  @media ${device.desktop} {
    font-size: 3.6rem;
    line-height: 4.8rem;
  }
`
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
export const Rotate = styled.div`
  animation: ${rotate} 1.5s linear infinite;
`
