import React, { ReactNode, FC } from 'react';

import * as S from './styled';

interface HeadingProps {
  center?: boolean
  children: ReactNode
  dark?: boolean
  headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  size: 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall'
}

const Heading: FC<HeadingProps> = ({
  center = false,
  dark = false,
  headingLevel,
  children,
  size
}: HeadingProps) => (
  <S.Heading as={headingLevel} center={center} dark={dark} size={size}>
    {children}
  </S.Heading>
);

export default Heading;
