import styled from 'styled-components'
import { device } from '../../../styles/breakpoints'

export const Section = styled.section`
  padding: 40px 24px;

  @media ${device.tablet} {
    padding: 80px 0;
  }

  @media ${device.desktop} {
    padding: 120px 0 100px 0;
  }
`

export const Wrapper = styled.div`
  grid-column: 1/-1;
`

export const LinksWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
`

export const Description = styled.p`
  font-family: 'Inter';
  font-weight: 400;
  max-width: 90%;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 48px 0;

  @media ${device.tablet} {
    font-size: 2rem;
    line-height: 3.5rem;
  }

  @media ${device.laptop} {
    font-size: 2rem;
    max-width: 80%;
    line-height: 4rem;
  }

  @media ${device.laptopL} {
    font-size: 3.6rem;
    line-height: 4.8rem;
    margin-bottom: 85px;
  }
`
