import styled from 'styled-components'
import { device } from '../../../styles/breakpoints'

export const Section = styled.section`
  position: relative;
  background-color: #fe3e6d;

  @media ${device.tablet} {
    padding: 90px;
  }
`

export const LeftSide = styled.div`
  padding: 24px;
  width: 100%;

  @media ${device.mobile} {
    padding: 24px 24px 32px;
  }

  @media ${device.tablet} {
    grid-column: 1/7;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
  }

  @media ${device.laptopL} {
    grid-column: 1/7;
  }
`

export const RightSide = styled.div`
  padding: 32px 0 32px 24px;

  @media ${device.tablet} {
    grid-column: 7/-1;
    padding: 64px 0;
  }

  @media ${device.laptopM} {
    grid-column: 8/-1;
    padding: 0;
  }

  @media ${device.desktop} {
    grid-column: 7/-1;
    padding: 0;
  }
`

export const Title = styled.h1`
  font-family: 'Inter';
  color: #ffffff;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 5.6rem;

  @media ${device.mobile} {
    font-size: 3.6rem;
    line-height: 7.2rem;
  }

  @media ${device.tabletL} {
    font-size: 3.2rem;
    line-height: 6.4rem;
  }

  @media ${device.laptop} {
    font-size: 5rem;
    line-height: 7.6rem;
    margin-bottom: 16px;
  }

  @media ${device.laptopM} {
    font-size: 8rem;
    line-height: 9.6rem;
    max-width: 1035px;
  }
`

export const Text = styled.p`
  font-family: 'Inter';
  color: #ffffff;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  max-width: 270px;

  @media ${device.mobile} {
    font-size: 2rem;
    line-height: 3.5rem;
  }

  @media ${device.tablet} {
    font-size: 1.8rem;
    line-height: 3rem;
  }

  @media ${device.laptop} {
    font-size: 2.8rem;
    line-height: 4.6rem;
    max-width: 606px;
  }

  @media ${device.laptopL} {
    font-size: 3rem;
    line-height: 5rem;
    max-width: 500px;
  }

  @media ${device.desktop} {
    font-size: 4.8rem;
    line-height: 6.4rem;
    max-width: 620px;
  }
`
