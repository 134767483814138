import React from 'react'
import Heading from '../../../components/Heading'
import FormContact from './FormContact'
import * as Styled from './styled'

const ContactHelpOmbudsman = (): JSX.Element => (
  <Styled.Section>
    <div className="container container--no-mobile-margin">
      <Styled.Wrapper>
        <Heading size="xlarge" headingLevel="h2">
          Precisa de ajuda?
        </Heading>

        <Styled.TextContainer>
          <Styled.Text>
            A Ouvidoria Cora ajuda os seus clientes e demais consumidores na
            solução de chamados que não foram resolvidos na central de
            atendimento (chat, whatsapp e e-mail) ou quando não se concorda com
            a tratativa que recebeu.
          </Styled.Text>
          <Styled.Subtitle>
            Não considerou a resolução do seu caso satisfatória?
          </Styled.Subtitle>
          <Styled.Text>
            Nossa Ouvidoria pode te ajudar! Preencha o formulário abaixo com seu
            protocolo de atendimento.
          </Styled.Text>
          <Styled.Text>
            Para acionar a Ouvidoria Cora é preciso já ter entrado em contato
            com o Time de Atendimento, onde é fornecido o protocolo de
            atendimento.
          </Styled.Text>
        </Styled.TextContainer>

        <FormContact />

        <Styled.ContactHelpText>
          Você também pode acessar nossa Ouvidoria gratuitamente através do
          telefone,
          <Styled.ContactHelpPhoneText as="span">
            0800 591 2431
          </Styled.ContactHelpPhoneText>
          nos dias úteis, de segunda a sexta, das 09:00 as 18:00.
        </Styled.ContactHelpText>
      </Styled.Wrapper>
    </div>
  </Styled.Section>
)

export default ContactHelpOmbudsman
