import styled from 'styled-components'
import { device } from '../../styles/breakpoints'

export const ButtonFile = styled.button`
  background-color: #ffffff;
  border-radius: 1.6rem;
  font-size: 1.6rem;
  padding: 2rem;
  width: 100%;
  min-height: 48px;
  border: none;
  cursor: pointer;
  flex-wrap: wrap;
  text-align: left;

  @media ${device.laptopM} {
    min-height: 64px;
  }
`

export const Placeholder = styled.span`
  color: #6b7076;
  font-size: 1.6rem;
  line-height: 2.4rem;
`
