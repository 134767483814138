import React from 'react'
import Modal from '../../../../../../components/Modal'
import { dataModal } from './data'
import { ModalOmbudsmanProps } from './interface'

const ModalOmbudsman = ({
  status,
  setStatus,
}: ModalOmbudsmanProps): JSX.Element | null => {
  if (!status) return null
  const handleClose = () => status !== 'loading' && setStatus(undefined)
  return (
    <Modal
      active={status ? true : false}
      type={dataModal[status].type}
      title={dataModal[status].title}
      subtitle={dataModal[status].subtitle}
      onClose={handleClose}
    />
  )
}

export default ModalOmbudsman
