import styled from 'styled-components'
import { device } from '../../styles/breakpoints'

export const InputSelect = styled.select`
  background-color: #ffffff;
  border-radius: 1.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1rem 1.4rem;
  width: 100%;
  height: 48px;
  border: none;

  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(
      45deg,
      transparent 50%,
      #6b7076 50% calc(50% + 1px),
      transparent calc(50% + 2px)
    ),
    linear-gradient(
      -45deg,
      transparent 50%,
      #6b7076 50% calc(50% + 1px),
      transparent calc(50% + 2px)
    );
  background-position: right 28px top calc(1.3em), right 20px top calc(1.3em);
  background-size: 8px 8px;
  background-repeat: no-repeat;

  @media ${device.laptopM} {
    background-position: right 28px top calc(1.8em), right 20px top calc(1.8em);
    height: 64px;
  }

  :invalid {
    color: #6b7076;
  }

  &.error {
    border-color: #e74c4c;
  }
`
