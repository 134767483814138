import styled from 'styled-components'

interface LinkStyledProps {
  dark: boolean
}

export const Link = styled.a<LinkStyledProps>`
  display: inline-flex;
  align-items: center;
  color: ${({ dark }) => (dark ? '#202020' : '#FE3E6D')};
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;
  transition: all .3s ease-in-out;
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;

  @media screen and (min-width: 1280px) {
    font-size: 22px;
    line-height: 32px;
  }

  &:hover {
    border-bottom: 2px solid ${({ dark }) => (dark ? '#202020' : '#FE3E6D')};
  }
`
