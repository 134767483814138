import styled from 'styled-components'
import { device } from '../../../styles/breakpoints'

export const Section = styled.section`
  padding: 40px 24px;

  @media ${device.tablet} {
    padding: 80px 0;
  }

  @media ${device.laptop} {
    padding: 100px 0;
  }

  @media ${device.desktop} {
    padding: 120px 0;
  }
`

export const Wrapper = styled.div`
  grid-column: 1/-1;
`

export const Description = styled.p`
  font-family: 'Inter';
  font-weight: 400;
  max-width: 90%;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 40px;
  margin-top: 30px;

  @media screen and (min-width: 425px) {
    font-size: 2rem;
    line-height: 3.5rem;
    max-width: 90%;
  }

  @media ${device.laptop} {
    font-size: 2rem;
    line-height: 4rem;
    max-width: 90%;
  }

  @media ${device.laptopL} {
    font-size: 3.6rem;
    line-height: 4.8rem;
    max-width: 90%;
    margin-bottom: 85px;
  }
`

export const ContactList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  grid-gap: 4rem;
  padding: 0 40px;

  @media ${device.tablet} {
    grid-gap: 8rem;
  }

  @media ${device.laptop} {
    grid-template-columns: repeat(4, 1fr);
    padding: 0;
    grid-column-gap: 10rem;
  }
`

export const ContactEmphasis = styled.span`
  font-family: 'Inter';
  font-weight: 600;
  color: #fe3e6d;
`

export const ContactIcon = styled.svg`
  width: 58px;
  height: 52px;

  @media ${device.tabletL} {
    width: 92px;
    height: 78px;
  }
`
