import React from 'react'
import Heading from '../../../components/Heading'
import * as Styled from './styled'

const ContactPhoneOmbudsman = (): JSX.Element => (
  <Styled.Section>
    <div className="container container--no-mobile-margin">
      <Styled.Wrapper>
        <Heading size="xlarge" headingLevel="h2">
          Estamos aqui para te atender
        </Heading>
        <Styled.DescriptionWrapper>
          <Styled.Description fontWeight="500">
            Se precisar de mais informações, ligue para:
          </Styled.Description>
          <Styled.Description fontWeight="700" color="#FE3E6D">
            (11) 5039-5715 <span>(capitais e regiões metropolitanas)</span>
          </Styled.Description>
          <Styled.Description fontWeight="700" color="#FE3E6D">
            0800 591 2350 <span>(demais localidades)</span>
          </Styled.Description>
        </Styled.DescriptionWrapper>
      </Styled.Wrapper>
    </div>
  </Styled.Section>
)

export default ContactPhoneOmbudsman
