import React from 'react'
import { TooltipProps } from './interface'
import * as Styled from './styled'

const Tooltip = ({ children, color }: TooltipProps): JSX.Element => (
  <Styled.Tooltip color={color}>
    <span className="arrow"></span>
    {children}
  </Styled.Tooltip>
)

export default Tooltip
