import React from 'react'
import Tooltip from '../../../../../../components/Tooltip'
import * as Styled from '../../styled'
import { FieldErrorProps } from './interface'


const FieldError = ({ children, errors }: FieldErrorProps): JSX.Element => (
  <>
    {children && (
      <Styled.ErrorWrapper>
        <Tooltip color="#ffe9ea">
          <Styled.Error>{children}</Styled.Error>
        </Tooltip>
      </Styled.ErrorWrapper>
    )}
    {errors?.length && (
      <Styled.LabelWrapper>
        {errors.map((error: string, index: number) => (
          <Styled.Error key={index}>{error}</Styled.Error>
        ))}
      </Styled.LabelWrapper>
    )}
  </>
)

export default FieldError
