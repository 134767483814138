const replaceValue = (value: string) => value.replace(/[^0-9-]/g, '')

export const getMaskDocument = (value: string): string => {
  let documentValue = replaceValue(value)
  if (documentValue.length <= 11) return '999.999.999-99 '
  if (documentValue.length > 12) return '99.999.999/9999-99'
  return ''
}

export const getMaskPhone = (value: string): string => {
  if (value && unmask(value).length === 11) {
    return '(99) 9 9999 9999'
  }
  return '(99) 9999 9999*'
}

export const unmask = (value: string): string => {
  return value
    .replaceAll('.', '')
    .replaceAll('-', '')
    .replaceAll('_', '')
    .replaceAll(' ', '')
    .replaceAll('(', '')
    .replaceAll(')', '')
}
