import { unmask } from '../../../../../../utils/getMask'

const getData = (
  data: FormValues,
  attachments?: UploadObject[],
  phone?: string
) => {
  let dataSend: Data = {
    submittedAt: +new Date(),
    fields: [
      {
        name: 'firstname',
        value: data.firstname,
      },
      {
        name: 'motivo_do_contato',
        value: data.reason,
      },
      {
        name: 'uuu',
        value: data.subject,
      },
      {
        name: 'numeroprotocolo',
        value: data.protocolNumber,
      },
      {
        name: 'cpf_do_atual_titular',
        value: unmask(data.cpf_cnpj_current_owner),
      },
      {
        name: 'phone',
        value: phone ? unmask(phone) : '',
      },
      {
        name: 'email',
        value: data.email,
      },
      {
        name: 'descreva',
        value: data.describe,
      },
    ],
  }
  if (attachments?.length) {
    attachments.forEach((attachment) => {
      dataSend = {
        ...dataSend,
        fields: [
          ...dataSend.fields,
          {
            name: 'anexos',
            value: attachment.objects[0].url,
          },
        ],
      }
    })
  }
  return dataSend
}
export default getData
