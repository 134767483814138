import React, { FC, ReactNode } from 'react'
import { ArrowRight, ArrowUpRight } from '@corabank/icons'

import * as Styled from './styled'

interface LinkProps {
  dark?: boolean
  href: string
  download?: boolean
  downloadName?: string
  target?: '_blank' | '_self' | '_parent' | '_top'
  children: ReactNode
}

const Link: FC<LinkProps> = ({
  dark = false,
  href,
  target = '_self',
  download = false,
  downloadName = '',
  children,
}: LinkProps) => {
  const colorIcon = dark ? '#202020' : '#FE3E6D'

  const handleDownload = () => {
    if (download && downloadName !== '') return downloadName
    return download
  }

  return (
    <Styled.Link
      href={href}
      rel={target === '_blank' ? 'noopener noreferrer' : ''}
      target={target}
      dark={dark}
      download={handleDownload()}
    >
      {children}
      {target === '_blank' ? (
        <ArrowUpRight size="medium" color={colorIcon} />
      ) : (
        <ArrowRight size="medium" color={colorIcon} />
      )}
    </Styled.Link>
  )
}

export default Link
