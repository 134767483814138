import styled from 'styled-components'

export const Tooltip = styled.span<{ color: string }>`
  background-color: ${({ color }) => color};
  padding: 10px 16px;
  border-radius: 8px;

  .arrow {
    position: absolute;
    transform: translate(-15%, -130%);
    border-bottom: 16px solid ${({ color }) => color};
    border-right: 16px solid transparent;
    border-left: 16px solid transparent;
  }
`
