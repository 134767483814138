import { SetStateAction } from 'react'
import { converterBase64 } from '../../../../../../utils/converterBase64'
import { submitFiles } from './formContactAPI'

const preparedUploadFile = async (
  data: FormValues,
  files: File[],
  phone: string,
  setStatus: (value: SetStateAction<Loading>) => void
): Promise<void> => {
  setStatus('loading')
  let attachments: Promise<UploadValues>[] = []
  attachments = files.map(async (file): Promise<UploadValues> => {
    return {
      filename: `ouvidoria-${file.size.toString()}-${file.name}`,
      base64: await converterBase64(file),
    }
  })
  Promise.all(attachments).then((dataUpload) =>
    submitFiles({ dataUpload, data, phone, setStatus })
  )
}

export default preparedUploadFile
