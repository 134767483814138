import { sha256 } from 'js-sha256'
import dispatchConversionsAPI from './dispatchConversionsAPI'

export const dispatchEventConversionsAPI = (email: string): void => {
  dispatchConversionsAPI('CompleteRegistration', {
    user_data: {
      em: [sha256(email)],
    },
  })
}
