import React from 'react';
import * as Styled from './styled'
import { contacts } from './data';
import { Contact } from './interface.d';
import ContactItem from './ContactItem';
import Heading from '../../../components/Heading';

const ContactOmbudsman = (): JSX.Element => (
  <Styled.Section>
    <div className="container container--no-mobile-margin">
      <Styled.Wrapper>
        <Heading size="xlarge" headingLevel="h2">
          É seu primeiro contato com a gente?
        </Heading>
        <Styled.Description>
          Escolha o melhor canal de comunicação para o seu atendimento, disponível 24 horas:
        </Styled.Description>
        <Styled.ContactList>
          {contacts.map((contact: Contact, index: number) => <ContactItem key={`ombudsman-${index}`} data={contact} />)}
        </Styled.ContactList>
      </Styled.Wrapper>
    </div>
  </Styled.Section>
)

export default ContactOmbudsman
