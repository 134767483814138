import React from 'react'
import Img from 'gatsby-image'
import { getFluidImage } from '../../../hooks/getFluidImage'
import * as Styled from './styled'

const BannerOmbudsman = (): JSX.Element => (
  <Styled.Section>
    <div className="container container--no-mobile-margin">
      <Styled.LeftSide>
        <Styled.Title>Ouvidoria</Styled.Title>
        <Styled.Text>
          A sua experiência na Cora é importante para a gente.
        </Styled.Text>
      </Styled.LeftSide>
      <Styled.RightSide>
        <Img fluid={getFluidImage('hero-ouvidoria.png')} />
      </Styled.RightSide>
    </div>
  </Styled.Section>
)

export default BannerOmbudsman
