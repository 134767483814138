import styled from 'styled-components'
import { device } from '../../../styles/breakpoints'

export const Section = styled.section`
  padding: 40px 24px;

  @media ${device.tablet} {
    padding: 80px 0;
  }

  @media ${device.desktop} {
    padding: 100px 0 120px 0;
  }
`

export const Wrapper = styled.div`
  grid-column: 1/-1;
`

export const DescriptionWrapper = styled.div`
  margin-top: 48px;
`

export const Description = styled.p<{ fontWeight?: '500' | '700' }>`
  font-family: 'Inter';
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  color: ${({ color }) => (color ? color : '#202020')};
  max-width: 90%;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0;

  @media ${device.tablet} {
    font-size: 2rem;
    line-height: 3.5rem;
  }

  @media ${device.laptop} {
    font-size: 2rem;
    line-height: 4rem;
  }

  @media ${device.laptopL} {
    font-size: 3.6rem;
    line-height: 4.8rem;
  }

  span {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 400;
    color: #3b3b3b;
  }
`
